import React from "react";

const Input = ({ value, field, type, placeholder, onChangeInput }) => {
  return (
    <input
      type={type}
      id={field}
      className="input form-control"
      value={value}
      placeholder={placeholder}
      onChange={onChangeInput(field)}
    />
  );
};

export default Input;
