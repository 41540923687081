import React from "react";

const InputHolder = ({ label, field, error, className, children }) => {
  return (
      <div className={`input-holder ${className}`}>
        <label className="input-label" htmlFor={field}>{ label }</label>
          
        { children }
        
        <p className="error-text">{ error !== "" ? error : null }</p>
      </div>
      
  );
};

export default InputHolder;
