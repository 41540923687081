
export function validatePrice(price, biggerThan0 = false) {
  try {
    const priceString = price.split("€").join("").split(",").join("");
    const priceValue = parseInt(priceString);
    
    if (biggerThan0) {
      return priceValue > 0;
    }
    return priceValue >= 0;

  } catch(e) {
    return false;
  }
}