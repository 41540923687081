import React from "react";

import "./Thanks.scss";

const Thanks = ({ referenceNumber }) => {
  const reload = () => {
    window.location.reload();
  }

  return (
    <div className="form-holder thanks-holder">
      <p>Your vehicle collection request has been submitted. Reference number: { referenceNumber }</p>
      <p>NVD will collect your vehicle within 48 hours. Please ensure your vehicle is prepared for ease of collection.</p>
      <button onClick={reload} className="next-button btn btn-primary restart-btn">New Vehicle Collection Request</button>
    </div>
  )
};

export default Thanks;