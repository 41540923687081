/* eslint-disable no-useless-escape */

import React, { Component } from "react";
import ReCAPTCHA from 'react-google-recaptcha';

import Input from "../../components/Input/Input";
import YesNo from "../../components/YesNo/YesNo";
import InputHolder from "../../components/InputHolder/InputHolder";

import { validatePrice } from "../../helper/validate";

import "./GeneralInfo.scss";

const errorMessage = "Mandatory field. Please complete.";
const errorYesNoMessage = "Mandatory field. Please select value.";

const vehicleRegistationPattern = /^\d{1,3}(KK|WW|C|CE|CN|CW|D|DL|G|KE|KY|L|LD|LH|LK|LM|LS|MH|MN|MO|OY|SO|RN|T|TN|TS|W|WD|WH|WX)\d{1,6}$/
const emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
const phoneNumberRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im


class GeneralInfo extends Component {
  state = {
    submitDisabled: this.props.isCaptcha
  }

  componentDidMount() {
    window.history.pushState(null, null, "1");
  }

  submitStep = () => {
    if (this.state.submitDisabled) {
      alert("Please, pass ReCAPTCHA before proceeding");
      return
    }

    const { props } = this;
    
    props.clearErrors();
    
    let error = false;

    const validateYesNoSelectors = (selectors) => {
      selectors.forEach((selector) => {
        if (selector.value === undefined) {
          error = true;
          props.setError(selector.name, errorYesNoMessage);
        }
      })
    }

    if (!vehicleRegistationPattern.test(props.vehicle_registration)) {
      error = true;
      props.setError("vehicleRegistration", "Provide correct Reg Number. No spaces, only capital letters.");
    }
    if (!validatePrice(props.price, true)) {
      error = true;
      props.setError("price", "Price must be greater than 0");
    }
    if (props.dealership_name === "") {
      error = true;
      props.setError("dealershipName", errorMessage);
    }
    if (props.collection_location === "") {
      error = true;
      props.setError("collectionLocation", errorMessage);
    }
    if (props.collection_eircode === "") {
      error = true;
      props.setError("collectionEircode", errorMessage);
    }

    if (!emailRegex.test(props.email)) {
      error = true;
      props.setError("emailError", "Please enter correct Email Address");
    }
    if (!phoneNumberRegex.test(props.number)) {
      error = true;
      props.setError("numberError", "Please enter correct Phone Number");
    }

    if (props.comments === "") {
      error = true;
      props.setError("comments", errorMessage);
    }

    validateYesNoSelectors(props.useDisclosures);

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    if (error) return;

    props.nextStep();
  };

  enableSubmit = () => {
    this.setState({ submitDisabled: false });
  }

  renderYesNoSelectors(selectors) {
    return selectors.map((selector, index) => {
      if (selector.show_if_prev_value !== undefined) {
        const prevSelector = selectors[index - 1]

        if (prevSelector.value !== selector.show_if_prev_value) {
          return <></>
        }
      }

      return (
        <InputHolder label={selector.label} error={selector.error} key={index}>
          <YesNo isChecked={selector.value} value={selector.name} onChange={this.props.handleChange} />
        </InputHolder>
     )
    })
  }

  renderSubmitSection(className) {
    const { props } = this;

    return <div className={`${className} captcha`}>
      { props.isCaptcha &&
        <div className="input-holder">
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_SITE_API_KEY}
            onChange={this.enableSubmit}
          />
        </div>
      }

      <button disabled={this.state.submitDisabled} onClick={ this.submitStep } className="next-button btn btn-primary">
        Submit
      </button>
      { props.isError() 
        ? <p className="error-text">Please complete all fields before proceeding.</p>
        : null
      }
    </div>
  }

  render() {
    const { props } = this;

    return (
      <div className="form-holder">
        <div className="column">
          <InputHolder label="Vehicle Registration" field="vehicle_registration" error={props.vehicleRegistrationError}>
            <Input 
              value={props.vehicle_registration}
              onChangeInput={props.handleInputChange} 
              field="vehicle_registration" 
              placeholder="123D4567"
            />
          </InputHolder>
          
          <InputHolder 
            label="Auction Reserve Price (In Euros)"
            className="price-input-holder" field="price" error={props.priceError}
          >
            <Input
              type="text"
              value={props.price}
              onChangeInput={props.handlePrice} 
              field="price" 
              placeholder="€"
            />
          </InputHolder>

          <InputHolder label="Dealership Name" field="dealership_name" error={props.dealershipNameError}>
            <Input 
              value={props.dealership_name}
              onChangeInput={props.handleInputChange} 
              field="dealership_name" 
              placeholder="Dealership Name"
            />
          </InputHolder>
          
          <InputHolder label="Collection Location" field="collection_location" error={props.collectionLocationError}>
            <Input 
              value={props.collection_location}
              onChangeInput={props.handleInputChange} 
              field="collection_location" 
              placeholder="Collection Location"
            />
          </InputHolder>

          <InputHolder label="Collection Eircode" field="collection_eircode" error={props.collectionEircodeError}>
            <Input 
              value={props.collection_eircode}
              onChangeInput={props.handleInputChange} 
              field="collection_eircode" 
              placeholder="Collection Eircode"
            />
          </InputHolder>

          <div className="personal-data-holder">
            <InputHolder label="Submitted by" error={props.emailError}>
              <Input 
                value={props.email}
                type="email"
                onChangeInput={props.handleInputChange} 
                field="email" 
                placeholder="Email"
              />
            </InputHolder>
            <InputHolder error={props.numberError}>
              <Input 
                value={props.number}
                onChangeInput={props.handleInputChange} 
                field="number" 
                placeholder="Phone Number"
              />
            </InputHolder>
          </div>

          { this.renderSubmitSection('two-columns') }
        </div>

        <div className="second-column">
          { this.renderYesNoSelectors(props.useDisclosures) }

          <InputHolder label="Comments" field="comments" error={props.commentsError}>
            <textarea
              className="form-control text-area"
              value={props.comments}
              onChange={props.handleInputChange("comments")}
              placeholder="Vehicle highlights or mechanical issues"
            />
          </InputHolder>

          { this.renderSubmitSection('one-column') }
        </div> 
      </div>
    );
  }
}

export default GeneralInfo;
