import React from 'react';

import "./CheckBox.scss";
import Tick from "../../assets/icon/tick.svg";

const CheckBox = ({ isChecked }) => {
  const renderTick = () => {
    if (isChecked)
      return <img src={ Tick } alt="tick"/>
  }
  const checked = isChecked ? "checked-box" : "";
  return (
      <div className={ "check-box " + checked }>
        { renderTick() }
      </div>
  );
};

export default CheckBox;
