
import React from "react";

import TradebidLogo from "../../assets/img/tradebid-logo.png";
import "./Header.scss";

const Header = () => {
  return (
    <div className="header-container">
      <img className="tradebid-logo" src={TradebidLogo} alt="TradeBid Logo" />
      <h1 className="tradebid-header"><i>Vehicle Collection Request</i></h1>
    </div>
  )
}

export default Header;