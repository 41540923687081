
import React, { Component } from 'react';

import tradebid from "../../api/tradebid";

import GeneralInfo from "../../steps/GeneralInfo/GeneralInfo";
import Thanks from "../../steps/Thanks/Thanks";

import Header from '../Header/Header';

import "./App.scss";

class App extends Component {
  constructor(props) {
    super(props)

    this.state = {
      submitted: false,
      isCaptcha: !window.location.search.includes("?isCaptcha=no"),
  
      errors: {
        vehicleRegistration: "",
        price: "",
        dealershipName: "",
        collectionLocation: "",
        collectionEircode: "",
        emailError: "",
        numberError: "",
  
        log_book_present: "",
        full_history: "",
        partial_history: "",
        keys_present: "",
        operational_clutch: "", 
        comments: "",
      },
      
      //first step
      vehicle_registration: "",
      price: "€",
      dealership_name: "",
      collection_location: "",
      collection_eircode: "",
      email: "",
      number: "",
      
      //second step
      log_book_present: undefined,
      full_history: undefined,
      partial_history: false,
      keys_present: undefined,
      operational_clutch: undefined,
      comments: "",
  
      //Thanks
      referenceNumber: "",
    };
  }

  nextStep = () => {
    this.setState({submitted: true });
  };


  setError = (error, errorText) => {
    let { errors } = this.state;
    errors[error] = errorText;
    this.setState({ errors: errors })
  }

  isError = () => {
    let { errors } = this.state;
    for (let error in errors) {
      if (errors[error] !== "") return true;
    }
  }

  clearErrors = () => {
    let { errors } = this.state;
    for (let error in errors) {
      errors[error] = "";
    }
    this.setState({ errors: errors });
  }

  handleChange = (field, value) => {
    this.setState({ [field]: value });
  }

  handleInputChange = input => e => {
    this.handleChange(input, e.target.value);
  }

  handlePriceInput = input => e => {
    let priceString = e.target.value.split("€").join("").split(",").join("");

    // remove everything besides numbers
    priceString = priceString.replace(/[^0-9]/g, "");

    // add space every 3 digits
    const array = priceString.split("").reverse()
    priceString = ""
    for (let position = 0; position < array.length; position += 3) {
      priceString += array.slice(position, position + 3).join("") + ","
    }
    priceString = priceString.slice(0, priceString.length - 1) // remove last symbol
    priceString = priceString.split("").reverse().join("").trim()

    const price = `€${priceString}`;
    this.handleChange(input, price);
  }

  handleSubmitForm = () => {
    //Boolean to text
    const state = Object.assign({}, this.state);
    for (let piece in state) {
      if (typeof state[piece] === "boolean") {
        state[piece] = state[piece] ? "Yes" : "No";
      }
    }

    this.nextStep();

    tradebid.post("/mail", state)
      .then(({ data }) => {
        this.setState({ referenceNumber: data })
      })
      .catch((error) => {
        alert(error);
        this.setState({ submitted: false });
      }
    ); 
  }

  renderStep() {
    const { state } = this;

    if (!state.submitted) return (
      <GeneralInfo
        handleInputChange={this.handleInputChange} handleChange={this.handleChange} 
        setError={this.setError} isError={this.isError} clearErrors={this.clearErrors}

        vehicle_registration={state.vehicle_registration} vehicleRegistrationError={state.errors.vehicleRegistration}
        price={state.price} priceError={state.errors.price} handlePrice={this.handlePriceInput}
        dealership_name={state.dealership_name} dealershipNameError={state.errors.dealershipName}
        collection_location={state.collection_location} collectionLocationError={state.errors.collectionLocation}
        collection_eircode={state.collection_eircode} collectionEircodeError={state.errors.collectionEircode}
        
        email={state.email} emailError={state.errors.emailError}
        number={state.number} numberError={state.errors.numberError}

        comments={state.comments} commentsError={state.errors.comments}
        
        useDisclosures={[
          { label: "Log Book Present", name: "log_book_present", value: state.log_book_present, error: state.errors.log_book_present },
          { label: "Full-Service History?", name: "full_history", value: state.full_history, error: state.errors.full_history },
          { label: "Partial Service History?", name: "partial_history", show_if_prev_value: false, value: state.partial_history, error: state.errors.partial_history },
          { label: "All Keys Present?", name: "keys_present", value: state.keys_present, error: state.errors.keys_present },
          { label: "Fully Operational Clutch?", name: "operational_clutch", value: state.operational_clutch, error: state.errors.operational_clutch },
        ]}

        isCaptcha={state.isCaptcha}
        
        nextStep={ this.handleSubmitForm }
      />
    );

    if (state.referenceNumber) return (
      <Thanks referenceNumber={ state.referenceNumber } />
    )

    return (
      <div className="form-holder">
        <h4>Processing the request...</h4>
      </div>
    );
  }

  render() {
    this.isError();

    return (
      <div className="page">
        <Header />

        <div>
          { this.renderStep() }
        </div>
      </div>
    )
  }
}

export default App;

