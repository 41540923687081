import React from 'react';

import CheckBox from "../CheckBox/CheckBox";

import "./TextCheckBox.scss";

const TextCheckBox = ({ name, value, isChecked, handleCheckEvent }) => {
  const handleCheck = () => {
    handleCheckEvent(value, !isChecked);
  }

  const checkedTextHolder = isChecked ? "checked-text-holder" : "";
  const checked = isChecked ? "checked" : "";

  return (
      <div onClick={ handleCheck } className={ "text-check-box box " + checkedTextHolder }>
        <div className="check-box-holder">
          <CheckBox isChecked={ isChecked }/>
        </div>
        <p className={ "check-box-text " + checked }>{ name }</p>
      </div>
  );
};

export default TextCheckBox;
