import React from "react";

import TextCheckBox from "../TextCheckBox/TextCheckBox";

import "./YesNo.scss";

const YesNo = ({ value, isChecked, onChange }) => {

  return (
    <div className="yes-no-holder">
      <TextCheckBox
        handleCheckEvent={() => onChange(value, true)}
        isChecked={isChecked}
        name="Yes"
      />

      <TextCheckBox
        handleCheckEvent={() => onChange(value, false)}
        isChecked={isChecked === false}
        name="No"
      />
    </div>
  );
};

export default YesNo;
